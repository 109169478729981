import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Skeleton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { formatNumberWithDecimal } from '@pbt/pbt-ui-components/src/utils/numberUtils'

import Typography from '~/components/elements/Typography/Typography'
import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'

import { useGetNoShowCancellationPenaltyFee } from './useGetNoShowCancellationPenaltyFee'

const useStyles = makeStyles(
  (theme) => ({
    warningText: {
      color: theme.colors.important,
    },
  }),
  { name: 'CancellationPolicyText' },
)

export interface CancellationPolicyTextProps {
  isCancelDialog?: boolean
  isCardOnFileAlert?: boolean
  isConsentCheckbox?: boolean
}

const CancellationPolicyText = ({
  isCancelDialog,
  isConsentCheckbox,
  isCardOnFileAlert,
}: CancellationPolicyTextProps) => {
  const { t } = useTranslation(['Common'])
  const classes = useStyles()

  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)
  const isNoShowCancellationPenaltyEnabled =
    useSelector(getFeatureToggle(FeatureToggle.NO_SHOW_CANCELLATION_PENALTY)) &&
    isOmnichannel
  const isNoShowCancellationPenaltyCardOnFileAlertEnabled =
    useSelector(
      getFeatureToggle(
        FeatureToggle.NO_SHOW_CANCELLATION_PENALTY_CARD_ON_FILE_ALERT,
      ),
    ) && isOmnichannel

  const showDefaultConsent =
    isNoShowCancellationPenaltyEnabled &&
    !isNoShowCancellationPenaltyCardOnFileAlertEnabled
  const showConsentWithNoCardOnFileMessage =
    isNoShowCancellationPenaltyEnabled &&
    isNoShowCancellationPenaltyCardOnFileAlertEnabled

  const { penaltyFee, isPenaltyFeeQueryLoading, penaltyFeeQueryError } =
    useGetNoShowCancellationPenaltyFee()

  if (isPenaltyFeeQueryLoading) {
    return <Skeleton sx={{ mb: 1 }} variant="rectangular" width="100%" />
  }

  if (penaltyFeeQueryError || !penaltyFee) {
    if (isCancelDialog) {
      return (
        <Typography.H3 className={classes.warningText}>
          {t('Common:LATE_CANCELLATION_FEE_MESSAGE.DEFAULT')}
        </Typography.H3>
      )
    }

    if (isConsentCheckbox) {
      return (
        <>
          {showDefaultConsent && (
            <Typography.Paragraph className={classes.warningText}>
              {t('Common:CANCELLATION_POLICY_CONTENT_DEFAULT.DEFAULT')}
            </Typography.Paragraph>
          )}
          {showConsentWithNoCardOnFileMessage && (
            <>
              <Typography.H3 className={classes.warningText}>
                {t('Common:VERIFY_PET_PARENT_AWARE_OF_CANCELLATION_POLICY')}
              </Typography.H3>
              <Typography.Paragraph className={classes.warningText}>
                {t(
                  'Common:CANCELLATION_POLICY_CONTENT_WITH_CARD_ON_FILE_MESSAGE.DEFAULT',
                )}
              </Typography.Paragraph>
            </>
          )}
        </>
      )
    }

    if (isCardOnFileAlert) {
      return (
        <Typography.Paragraph className={classes.warningText}>
          {t('Common:CANCELLATION_POLICY_CONTENT_DEFAULT.DEFAULT')}
        </Typography.Paragraph>
      )
    }

    return null
  }

  const amountToDisplay = formatNumberWithDecimal(penaltyFee)

  if (isCancelDialog) {
    return (
      <Typography.H3 className={classes.warningText}>
        {t('Common:LATE_CANCELLATION_FEE_MESSAGE.WITH_AMOUNT', {
          amount: amountToDisplay,
        })}
      </Typography.H3>
    )
  }

  if (isConsentCheckbox) {
    return (
      <>
        {showDefaultConsent && (
          <Typography.Paragraph className={classes.warningText}>
            {t('Common:CANCELLATION_POLICY_CONTENT_DEFAULT.WITH_AMOUNT', {
              amount: amountToDisplay,
            })}
          </Typography.Paragraph>
        )}
        {showConsentWithNoCardOnFileMessage && (
          <>
            <Typography.H3 className={classes.warningText}>
              {t('Common:VERIFY_PET_PARENT_AWARE_OF_CANCELLATION_POLICY')}
            </Typography.H3>
            <Typography.Paragraph className={classes.warningText}>
              {t(
                'Common:CANCELLATION_POLICY_CONTENT_WITH_CARD_ON_FILE_MESSAGE.WITH_AMOUNT',
                {
                  amount: amountToDisplay,
                },
              )}
            </Typography.Paragraph>
          </>
        )}
      </>
    )
  }

  if (isCardOnFileAlert) {
    return (
      <Typography.Paragraph className={classes.warningText}>
        {t('Common:CANCELLATION_POLICY_CONTENT_DEFAULT.WITH_AMOUNT', {
          amount: amountToDisplay,
        })}
      </Typography.Paragraph>
    )
  }

  return null
}

export default CancellationPolicyText
