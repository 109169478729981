import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Box,
  Collapse,
  FormControl,
  Input,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Field, PuiCheckbox, PuiSelect, Utils } from '@pbt/pbt-ui-components'

import AutoshipIcon from '~/components/elements/Icons/generated/AutoshipIcon'
import Typography from '~/components/elements/Typography/Typography'
import {
  AUTOSHIP_FREQUENCY_OPTIONS_MAP,
  AutoshipFrequencyOption,
} from '~/constants/autoship'
import {
  getAutoshipUnit,
  getChewyFreeShippingEnabled,
} from '~/store/reducers/constants'

import { AutoshipRefillTooltip } from './AutoshipRefillTooltip'

interface AutoshipFrequencySelectProps {
  autoshipFrequencyField: Field
  autoshipUnitIdField: Field
  enableAutoshipField: Field
}

const StyledContainer = styled('div')`
  display: flex;
  width: 100%;
`

const StyledAutoshipCollapse = styled(Collapse)(({ theme }) => ({
  paddingTop: theme.spacing(0),

  '.MuiCollapse-wrapperInner': {
    display: 'flex',
  },
}))

const StyledPuiCheckbox = styled(PuiCheckbox)`
  display: flex;

  .MuiSvgIcon-root {
    margin-right: 5px;
  }
`

const StyledFormControl = styled(FormControl)`
  width: 180px;
`

const AutoshipFrequencySelect = ({
  autoshipFrequencyField,
  autoshipUnitIdField,
  enableAutoshipField,
}: AutoshipFrequencySelectProps) => {
  const { t } = useTranslation(['Common', 'Soap'])

  const autoshipUnitsList = useSelector(getAutoshipUnit)
  const isChewyFreeShippingEnabled = useSelector(getChewyFreeShippingEnabled)

  const initializedAutoshipFrequency: AutoshipFrequencyOption = {
    uom: Utils.getConstantName(autoshipUnitIdField?.value, autoshipUnitsList),
    value: autoshipFrequencyField?.value,
  }

  const enableAutoship = enableAutoshipField?.value ?? false

  const [autoshipFrequencyOption, setAutoshipFrequencyOption] =
    useState<AutoshipFrequencyOption>(initializedAutoshipFrequency)

  const handleSelectorChange = (event: SelectChangeEvent<unknown>) => {
    const selectedOption = AUTOSHIP_FREQUENCY_OPTIONS_MAP.find(
      (item) => item.id === (event.target.value as string),
    )?.option
    setAutoshipFrequencyOption(selectedOption || initializedAutoshipFrequency)
  }

  useEffect(() => {
    const autoshipUnitValue: string = Utils.findConstantIdByName(
      autoshipFrequencyOption.uom,
      autoshipUnitsList,
    )
    autoshipFrequencyField?.setValue(autoshipFrequencyOption.value)
    autoshipUnitIdField?.setValue(autoshipUnitValue)
  }, [autoshipFrequencyOption])

  return (
    <StyledContainer>
      <StyledPuiCheckbox
        field={enableAutoshipField}
        label={
          <Box alignItems="center" display="flex">
            <AutoshipIcon />
            <div>{t('Soap:AUTOSHIP.ADD_AUTOSHIP')}</div>
            {isChewyFreeShippingEnabled && (
              <Collapse
                collapsedSize={180}
                in={!enableAutoship}
                orientation="horizontal"
                sx={{
                  alignItems: 'center',
                  ml: 2,
                  display: 'flex',
                }}
              >
                <Typography.Paragraph color="grayGray2">
                  {t('Tooltips:FREE_SHIPPING_ON_FIRST_ORDER')}
                </Typography.Paragraph>
              </Collapse>
            )}
            <StyledAutoshipCollapse
              in={!enableAutoship}
              orientation="horizontal"
            >
              <AutoshipRefillTooltip />
            </StyledAutoshipCollapse>
          </Box>
        }
      />
      <Collapse in={enableAutoship} orientation="horizontal">
        <StyledFormControl>
          <InputLabel htmlFor="autoship-frequency-select">
            {t('Common:FREQUENCY_ONE')}
          </InputLabel>
          <PuiSelect
            input={<Input id="autoship-frequency-select" />}
            items={AUTOSHIP_FREQUENCY_OPTIONS_MAP}
            renderEmpty={false}
            value={
              autoshipFrequencyOption
                ? `${autoshipFrequencyOption.uom}-${autoshipFrequencyOption.value}`
                : ''
            }
            onChange={handleSelectorChange}
          />
        </StyledFormControl>
      </Collapse>
    </StyledContainer>
  )
}

export default AutoshipFrequencySelect
